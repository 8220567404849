import { ElementSelector } from "../../types";
import { addClass, removeClass } from "../../utils/dom";

/**
 * Used for controlling `Contact` drawer
 */
export class Contact {
  private container: HTMLElement;
  private body: HTMLElement;
  private _open: boolean;

  constructor($: ElementSelector) {
    this.container = $<HTMLElement>("#contact")!;
    this.body = $<HTMLBodyElement>("body")!;
    this._open = false;
  }

  private set open(val) {
    this._open = val;
    if (this._open) {
      addClass("open", this.container);
      addClass("scrollb", this.body);
      return;
    }

    removeClass("open", this.container);
    removeClass("scrollb", this.body);
  }

  private get open(): boolean {
    return this._open;
  }

  public toggle = (): boolean => {
    this.open = !this.open;

    return this.open;
  };

  public close = (): void => {
    this.open = false;
  };
}
