import { HTTPClient } from '../types';

export class API {
  constructor(private http: HTTPClient) {}

  public subscribe = <T>(data: any): Promise<T> => {
    return this.http.post('/mailing-list', { body: JSON.stringify(data) });
  };

  public resetPassword = <T>(data: any): Promise<T> => {
    return this.http.put('/reset-password', { body: JSON.stringify(data) });
  };
}
