import { API, FetchClient, Intl, Language, SimpleLogger } from '../services';
import { env } from '../services/environment';
import { addClass, click, removeClass, submit } from '../utils/dom';
import { polyfills } from '../polyfills';
import { ElementSelector, ElementsSelector, Logger } from '../types';
import { configureConsole } from '../utils/console';
import { Slider } from './components/slider';
import { Scrolr } from './components/scrolr';
import { Contact } from './components/contact';
import { Form } from './components/form';
import { Config } from '../config';

// import for sideffects
import 'tachyons';
import 'typeface-roboto';
import 'normalize.css';
import '../../assets/css/fontello/css/icon.css';

interface Elements {
  intl: {
    en: HTMLElement;
    nl: HTMLElement;
  };
  nav: {
    about: HTMLElement;
    features: HTMLElement;
  };
  buttons: {
    contact: HTMLElement;
    fullWcontact: HTMLElement;
    footerContact: HTMLElement;
    close: HTMLElement;
  };
  form: HTMLElement;
}

class App {
  private scrolr: Scrolr;
  private localize: Intl;
  private contact: Contact;
  private form: Form;
  private api: API;

  private elements: Elements;

  constructor($: ElementSelector, _$$: ElementsSelector, logger: Logger) {
    this.api = new API(new FetchClient(Config.URL(env)));

    this.contact = new Contact($);
    this.scrolr = new Scrolr($);
    this.localize = new Intl();
    this.form = new Form($, this.localize.t, this.api, logger);
    new Slider($);

    this.elements = this.initialize($);
  }

  public listen = (): void => {
    this.attachListeners(this.elements);

    this.scrolr.observe();
  };

  private initialize = ($: ElementSelector): Elements => {
    return {
      intl: {
        en: $<HTMLButtonElement>('#en')!,
        nl: $<HTMLButtonElement>('#nl')!,
      },
      nav: {
        about: $<HTMLAnchorElement>('#about-ns')!,
        features: $<HTMLAnchorElement>('#features-ns')!,
      },
      buttons: {
        contact: $<HTMLAnchorElement>('#contactBtn')!,
        fullWcontact: $<HTMLAnchorElement>('#fullwctnbtn')!,
        footerContact: $<HTMLSpanElement>('#footercontact')!,
        close: $<HTMLButtonElement>('#closebtn')!,
      },
      form: $<HTMLFormElement>('form')!,
    };
  };

  private attachListeners = (elements: Elements): void => {
    const { intl, buttons, form, nav } = elements;

    click(intl.en, this.handleIntlButton('en'));
    click(intl.nl, this.handleIntlButton('nl'));

    click(buttons.contact, this.handleContactButton);
    click(buttons.fullWcontact, this.handleContactButton);
    click(buttons.footerContact, this.handleContactButton);
    click(buttons.close, this.contact.close);

    submit(form, this.handleFormSubmit);

    click(nav.about, this.handleNavButton('about'));
    click(nav.features, this.handleNavButton('features'));
  };

  private handleIntlButton = (intl: 'en' | 'nl'): EventListener => {
    const activate = addClass('active');
    const deactivate = removeClass('active');

    const { en, nl } = this.elements.intl;

    switch (intl) {
      case 'en': {
        return () => {
          activate(en);
          deactivate(nl);
          this.localize.change(Language.EN);
        };
      }
      case 'nl': {
        return () => {
          activate(nl);
          deactivate(en);
          this.localize.change(Language.NL);
        };
      }
      default: {
        throw new Error('Unknown language!');
      }
    }
  };

  private handleContactButton = (ev: Event): void => {
    ev.preventDefault();
    this.contact.toggle();
  };

  private handleFormSubmit = (ev: Event): void => {
    ev.preventDefault();
    this.form.submit();
    this.form.reset();
  };

  private handleNavButton = (btn: 'about' | 'features'): EventListener => {
    const activate = addClass('active');
    const deactivate = removeClass('active');

    const { about, features } = this.elements.nav;

    switch (btn) {
      case 'about': {
        return () => {
          activate(about);
          deactivate(features);
        };
      }
      case 'features': {
        return () => {
          activate(features);
          deactivate(about);
        };
      }
      default: {
        throw new Error('Unknown navigation button!');
      }
    }
  };
}

const startApp = (): void => {
  const $ = document.querySelector.bind(document);
  const $$ = document.querySelectorAll.bind(document);
  const logger = new SimpleLogger(env);

  try {
    new App($, $$, logger).listen();
  } catch (error) {
    logger.error(error.message);
  }
};

window.onload = () => {
  polyfills().then(() => {
    configureConsole(env);
    startApp();
  });
};
