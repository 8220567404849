import { tns } from "tiny-slider/src/tiny-slider";

const event = tev => (elem, handler) => {
  elem.addEventListener(tev, handler);
};

const click = event("click");

export interface ITinySliderOptions {
  container?: Node | string;
  items?: number;
  slideBy?: "page" | number;
  controls?: boolean;
  mode?: "carousel" | "gallery";
  axis?: "horizontal" | "vertical";
  controlsContainer?: Node | string | false;
  navContainer?: Node | string | false;
  navAsThumbnails?: boolean;
  autoplayDirection?: "forward" | "backward";
  autoplayButton?: Node | string | false;
  autoplayButtonOutput?: boolean;
  animateIn?: string;
  animateOut?: string;
  animateNormal?: string;
  animateDelay?: number | false;
  loop?: boolean;
  rewind?: boolean;
  lazyload?: boolean;
  swipeAngle?: number | boolean;
  nested?: "inner" | "outer" | false;
  freezable?: boolean;
  onInit?: Function | false;
}

export interface ITinySlider {
  constructor(options?: ITinySliderOptions): ITinySlider;
  getInfo(): any;
  events(): any;
  goTo(index: number | string): void;
  play(): void;
  pause(): void;
  isOn(): boolean;
  rebuild(): void;
  destroy(): void;
}

const CONFIG: Partial<ITinySliderOptions> = {
  container: ".slider",
  items: 1,
  slideBy: "page",
  mode: "carousel",
  controls: false,
  navContainer: ".slidernav"
};

export class Slider {
  private slider: ITinySlider;

  constructor($) {
    this.slider = tns(CONFIG);

    click($(".left"), this.prev);
    click($(".right"), this.next);
  }

  public get current(): number {
    return this.slider.getInfo().index;
  }

  public next = (): void => {
    this.slider.goTo("next");
  };

  public prev = (): void => {
    this.slider.goTo("prev");
  };

  public goTo(nn: number): void {
    this.slider.goTo(nn);
  }
}
