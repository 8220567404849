import { HTTPClient } from '../types';

export class FetchClient implements HTTPClient {
  constructor(private url: string) {}

  private checkResponse = (response: Response): Promise<Response> => {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response);
    }

    return Promise.reject(response);
  };

  public request<T>(url: string, options: RequestInit): Promise<T> {
    return fetch(this.url + url, {
      headers: { 'Content-Type': 'application/json' },
      ...options,
    })
      .then(this.checkResponse)
      .then(response => response.json());
  }

  public get<T>(url: string, options: RequestInit): Promise<T> {
    return this.request(url, { method: 'GET', ...options });
  }

  public post<T>(url: string, options: RequestInit): Promise<T> {
    return this.request(url, { method: 'POST', ...options });
  }

  public delete<T>(url: string, options: RequestInit): Promise<T> {
    return this.request(url, { method: 'DELETE', ...options });
  }

  public put<T>(url: string, options: RequestInit): Promise<T> {
    return this.request(url, { method: 'PUT', ...options });
  }
}
