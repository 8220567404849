import * as i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import { init } from 'loc-i18next';

export interface IntlConfig {
  selectorAttr: string;
  targetAttr?: string;
  optionsAttr?: string;
  useOptionsAttr?: boolean;
  parseDefaultValueFromContent?: boolean;
}

export type IntlFn = (t: string) => string;

export enum Language {
  EN = 'en',
  NL = 'nl',
}

export class Intl {
  private _current: Language;
  private i18n: i18n.i18n;
  private localize;
  private selector: string = '[data-i18n]';

  constructor(
    config: IntlConfig = {
      selectorAttr: 'data-i18n',
      parseDefaultValueFromContent: true,
    },
  ) {
    this._current = Language.EN;
    this.i18n = i18n.use(XHR).init(
      {
        lng: this.current,
        fallbackLng: Language.EN,
        backend: { loadPath: 'locales/{{lng}}/translation.json' },
      },
      err => {
        if (err) {
          throw err;
        }
        this.localize = init(this.i18n);
        this.localize(this.selector);
      },
    );

    this.i18n.on('languageChanged', () => {
      this.localize ? this.localize(this.selector) : void 0;
    });
  }

  public get current(): Language {
    return this._current;
  }

  public get t(): IntlFn {
    return this.i18n.t.bind(this.i18n);
  }

  public change = (lang: Language): void => {
    if (lang === this.current) {
      return;
    }
    this.i18n.changeLanguage(lang);
    document.documentElement.lang = lang;
    this._current = lang;
  };
}
