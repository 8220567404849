import { Env, Logger } from '../types';

export class SimpleLogger implements Logger {
  constructor(private env: Env) {}

  private get sendLogs(): boolean {
    return this.env === Env.PROD;
  }

  public error = (data: string): void => {
    if (!this.sendLogs) {
      return;
    }

    ga('send', 'exception', {
      exDescription: data,
      exFatal: false,
    });
  };

  public debug = (data: string): void => {
    console.log(`[DBG]: ${data}`);
  };
}
