export enum Env {
  DEV = 'dev-api',
  STAGING = 'staging',
  PROD = 'api',
}

export const __DEV__ = process.env.ENV === 'dev';
export const __PROD__ = process.env.ENV === 'production';

export const env = __DEV__ ? Env.DEV : Env.STAGING;
