export type ElementSelector = typeof document.querySelector;
export type ElementsSelector = typeof document.querySelectorAll;

export interface Response<T = {}, E = string> {
  data: T;
  error: boolean;
  message: E;
  pagination: {};
  pending: [];
}

export interface ErrorResponse extends Response {
  error: true;
}

export interface SuccessResponse extends Response {
  error: false;
}

export interface Logger {
  error: (data: string) => void;
  debug: (data: string) => void;
}

export enum Env {
  DEV = 'dev-api',
  STAGING = 'staging',
  PROD = 'api',
}

export interface HTTPClient {
  request<T>(url: string, options: RequestInit): Promise<T>;
  post<T>(url: string, options: RequestInit): Promise<T>;
  get<T>(url: string, options: RequestInit): Promise<T>;
  put<T>(url: string, options: RequestInit): Promise<T>;
  delete<T>(url: string, options: RequestInit): Promise<T>;
}
