import detectPassiveEvents from "detect-passive-events";
import rafSchd from "raf-schd";

/**
 * Config that specifies which DOM
 * Elements to hook into.
 */
const ScrolrConfig = {
  HERO: "#hero",
  LINKR: "#linkr",
  NAV: "header"
};

/**
 * Intesection Observer configuration.
 */
const IOConfig = {
  root: null,
  rootMargin: "-10px",
  threshold: 0
};

export class Scrolr {
  private hero: HTMLElement;
  private nav: HTMLElement;
  private linkr: HTMLElement;
  private io: IntersectionObserver;

  constructor($) {
    this.hero = $(ScrolrConfig.HERO);
    this.linkr = $(ScrolrConfig.LINKR);
    this.nav = $(ScrolrConfig.NAV);
    this.io = new IntersectionObserver(this.onChange, IOConfig);
  }

  private onChange: IntersectionObserverCallback = entries => {
    if (!entries[0].isIntersecting) {
      this.linkr.classList.remove("invisible");
      return;
    }

    this.linkr.classList.contains("invisible")
      ? void 0
      : this.linkr.classList.add("invisible");
  };

  private onTop = rafSchd((top: number) => {
    if (top > 10) {
      this.nav.classList.add("nav-shadow");
      return;
    }
    this.nav.classList.remove("nav-shadow");
  });

  public observe(): void {
    this.io.observe(this.hero);
    window.addEventListener(
      "scroll",
      () => this.onTop(window.scrollY),
      detectPassiveEvents.hasSupport ? { capture: false, passive: true } : {}
    );
  }
}
